import TopBar from '../Components/Topbar'
import 'dracula-ui/styles/dracula-ui.css'
import { List , Avatar ,Heading ,Paragraph, Box, Button,Input, Divider } from 'dracula-ui'
import WorkInProgress from '../Components/WorkInProgress'
import Intro from '../Components/Intro'
import Article from '../Components/Article'
import Footer from '../Components/Footer'

export default function HomePage() {
    return (
        <Box pb="lg" pl="lg" pr='lg' style={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Heading size='2xl' color='yellowPink' style={{
                textAlign: "center"
            }} >
                Le Blog d'un geek comme les autres
            </Heading>
            <WorkInProgress/>
            <Intro/>
            <Article/>
            <Article/>
            <Article/>
            <Button disabled={true} variant="outline" color="purple" >
                voir plus d'articles
            </Button>
        </Box>
    )
}
