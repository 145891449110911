import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from './Pages/HomePage';
import Footer from './Components/Footer';
import TopBar from './Components/Topbar';


function App() {
  return (
    <div >
      <TopBar/>
      <HomePage />
      <Footer/>
    </div>
  );
}

export default App;
