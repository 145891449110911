import { List , Avatar ,Heading ,Paragraph, Box, Button,Input, Divider } from 'dracula-ui'
import 'dracula-ui/styles/dracula-ui.css'
import placehodler from '../Static/placeholder.svg'
import Link from "../Components/Link"

export default function Footer() {
    return (
        <>
            <Divider color='purple' style={{
                width: "100vw",
            }}/>
            <Paragraph style={{
                textAlign: "center",
            }}>
                Fait avec <Link href='https://fr.react.dev'>React</Link> et la bibliotheque <Link href='https://ui.draculatheme.com'>Dracula ui</Link>, I use arch btw  - 2023 Djalim Simaila
            </Paragraph>
        </>
    )
}