import {
    List,
    Avatar,
    Heading,
    Paragraph,
    Box,
    Button,
    Input,
    Divider,
} from "dracula-ui";
import "dracula-ui/styles/dracula-ui.css";
import placehodler from "../Static/placeholder.svg";
import { useState } from "react";

export default function Article() {
    const [hovering, setHovering] = useState(false);

    return (
        <>
            <Box
                p="sm"
                display="flex"
                color={hovering ? "black" : "blackSecondary"}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                style={
                    hovering
                        ? {
                              cursor: "pointer",
                              borderRadius: "1rem",
                              transform: "scale(1.02)",
                              transition: "all 0.2s",
                          }
                        : {
                              borderRadius: "1rem",
                              transition: "all 0.2s",
                          }
                }
            >
                <img src={placehodler} alt="placeholder" width="250rem" />
                <Divider m="sm" color="purple" />
                <Box>
                    <Heading size="lg">Titre de l'article</Heading>
                    <Paragraph color="greySecondary">
                        posté le 01/01/1970 par <b>Neotaku67</b>
                    </Paragraph>
                    <Paragraph>
                        Alors, de base il doit y avoir du texte ici mais comme
                        le parser Markdown n'est pas encore fait, il n'y a rien.
                    </Paragraph>
                </Box>
            </Box>
        </>
    );
}
