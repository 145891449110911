import 'dracula-ui/styles/dracula-ui.css'
import { Paragraph, Box, Button,Input } from 'dracula-ui'


export default function TopBar(){
    return (
        <Box color="animated" m="sm" p="sm" style={{
            borderRadius: "1rem",
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            width: "auto",

        }}>
            <Button onClick={
                () => {
                    window.location.href = "/"
                }
            } style={{
                backgroundColor:"hsl(232, 14%, 31%)",
                color: "#D1D1D1",
            }}>
                Accueil
            </Button>
            <Button onClick={
                () => {window.location.href = "https://gitea.simailadjalim.fr/DjalimSimaila"}
            } style={{
                backgroundColor:"hsl(232, 14%, 31%)",
                color: "#D1D1D1",
            }}>
                Mes projets
            </Button>
            <Button onClick={
                () => {window.location.href = "https://www.linkedin.com/in/djalim-simaila/"}
            } style={{
                backgroundColor:"hsl(232, 14%, 31%)",
                color: "#D1D1D1",
            }}>
                LinkedIn
            </Button>
            <Button onClick={
                () => {window.location.href = "mailto:contact@mail.simailadjalim.fr"}
            } style={{
                backgroundColor:"hsl(232, 14%, 31%)",
                color: "#D1D1D1",
            }}>
                Contactez moi
            </Button>
            <Button disabled={true} variant="outline" color="purple">
                Articles
            </Button>
            <Input color='purple' placeholder='Rechercher un article' style={{width: "25rem"}}>
            </Input>
        </Box>
    )
}