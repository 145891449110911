import { List , Avatar ,Heading ,Paragraph, Box, Button,Input, Divider } from 'dracula-ui'
import Link from "../Components/Link"
import mwa from '../Static/mwa.jpg'
import 'dracula-ui/styles/dracula-ui.css'

export default function Intro() {
    return (
        <Box p='sm' display='flex' color='blackSecondary' style={{
            borderRadius: "1rem",
            flexDirection: "column",
            alignItems: "center",
        }} >
            <Heading p='sm'>
                Bienvenue sur mon blog !
            </Heading>
            <Divider color="purple" style={{
                width: "100%",
            }} />
            <Box display='flex'>
            <img src={mwa} alt="" width="300rem" style={{
                borderRadius: "1rem",
            }} />
                <Divider m="sm" color="purple"/>
                <Box>
                    <Paragraph>
                        Je m'appelle <b>Djalim</b>, j'ai 20 ans et je suis un developpeur et etudiant en BUT Informatique à Aix en Provence.
                    </Paragraph>
                    <Paragraph>
                        Je suis passioné d'Informatique, je sais que c'est tres vague mais ça englobe tout ce que j'aime faire :
                    </Paragraph>
                    <List variant='unordered' color='white'>
                        <li className="drac-text drac-text-white">
                            Créer des logiciels (scripts, applications, jeux, you name it, I do it)
                        </li>
                        <li className="drac-text drac-text-white">
                            Créer et configurer des serveurs/workstations Linux (Debian, Ubuntu, Arch, Manjaro, Fedora, CentOS, etc...)
                        </li>
                        <li className="drac-text drac-text-white" >
                            Créer des api et des bases de données 
                        </li>
                    </List>
                    <Paragraph>
                        Bref, je fais plein de trucs. Je suis aussi passioné par les mangas, les jeux vidéos et les animes. 
                    </Paragraph>
                </Box>
            </Box>
            <Divider color="purple" style={{
                width: "100%",
            }} />
            <Paragraph>
                discord : @neotaku67
                twitter : <Link href="https://twitter.com/NeotakUwU" >@neotakUwU </Link>
                steam : <Link href="https://steamcommunity.com/id/Neotaku67/" >neotaku67 </Link>
                osu: <Link href="https://osu.ppy.sh/users/8664491" >neotaku67 </Link>
            </Paragraph>
        </Box>
        )
    }