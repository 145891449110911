import {
    List,
    Avatar,
    Heading,
    Paragraph,
    Box,
    Button,
    Input,
    Divider,
} from "dracula-ui";
import "dracula-ui/styles/dracula-ui.css";

export default function Link({ href, children }: { href: string; children: React.ReactNode}) {
    return (
        <a href={href} className="drac-text drac-text-pink-purple" >
            {children}
        </a>
    )
}
