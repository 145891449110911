import { List , Avatar ,Heading ,Paragraph, Box, Button,Input, Divider } from 'dracula-ui'
import mwa from '../Static/mwa.jpg'
import 'dracula-ui/styles/dracula-ui.css'

export default function WorkInProgress() {
    return (
        <Box display='flex' color='blackSecondary' style={{
            borderRadius: "1rem",
            flexDirection: "column",
            alignItems: "center",
        }} >
            <Heading size='2xl' p='md' >
                🛠️ Site en construction 🛠️
            </Heading>
            <Paragraph mt='xxs'>
                Le site n'est pas encore terminé, mais vous pouvez déjà voir mes projets sur mon Gitea !
            </Paragraph>
        </Box>
    )
}